@import '@/assets/styles/variable.scss';
.home-products {
    background: #fff;
    .show-box {
        display: flex;
        justify-content: space-between;
        width: $width;
        margin: 0 auto;
        .product {
            &-app {
                display: flex;
                flex-direction: column;
                margin-top: 126px;
            }
            &-title {
                color: #16a83f;
                font-weight: bold;
                font-size: 24px;
            }
            &-intro {
                width: 480px;
                margin-top: 10px;
                color: #666;
                line-height: 21px;
            }
            &-details {
                display: flex;
                margin-top: 30px;
                img {
                    width: 80px;
                    height: 80px;
                    margin-right: 15px;
                }
                &-subtitle {
                    margin-top: 10px;
                    font-size: 16px;
                }
                &-tips {
                    margin-top: 10px;
                    color: #666;
                    font-size: 12px;
                    line-height: 18px;
                }
            }
            &-introduction img {
                width: 620px;
                height: 445px;
            }
        }
    }
}
