.App {
    min-width: 1180px;
    min-height: 100%;
}
.content {
    display: flex;
    justify-content: center;
}
.loading {
    padding-top: 40px;
    :global(.ant-spin-text) {
        color: #222;
    }
}
