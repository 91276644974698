.box {
    width: 280px;
}

.tab {
    :global(.ant-tabs-nav::before) {
        border-bottom: none;
    }

    :global(.ant-tabs-nav) {
        margin-bottom: 20px;
    }

    :global(.ant-tabs-tab) {
        margin-right: 0;
        font-size: 18px;
    }

    :global(.ant-tabs-nav-list) {
        justify-content: space-around;
        width: 100%;
    }

    :global(.ant-tabs-content-holder) {
        width: 100%;
    }

    :global(.ant-form-item-explain) {
        min-height: 0;
        > div {
            height: 24px;
            min-height: 0;
        }
    }
}

.input {
    width: 100%;
}

.extra {
    display: flex;
    justify-content: space-between;

    button {
        padding: 0;
    }
}

.login-button {
    width: 100%;
}

.protocol {
    position: relative;
    margin-top: 8px;
    font-size: 12px;

    :global(.ant-checkbox-wrapper) {
        font-size: 12px;
    }

    button {
        padding: 0;

        span {
            font-size: 12px;
        }
    }
}

.combine-input {
    width: 100%;

    .input {
        width: 178px;
    }

    .button {
        height: 40px;
        border: 1px solid #e5e5e5;
    }
}

.code-button {
    height: 25px;
    padding: 0;
}

.protocol-tip {
    position: absolute;
    top: 26px;
    left: 8px;
    width: 143px;
    height: 29px;
    color: #fff;
    line-height: 36px;
    text-align: center;
    background: url('~@/assets/images/login/protocol-bg.png') no-repeat;
}
