@import '@/assets/styles/variable.scss';
.home-flow {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 55px 0;
    background: $pageBackground;
    title {
        font-size: 24px;
        text-align: center;
    }
}
.flows {
    display: flex;
    justify-content: space-between;
    margin-top: 44px;
    .flow {
        &-item {
            display: flex;
            text-align: center;
            > div {
                width: 168px;
            }
            &::after {
                position: relative;
                top: 80px;
                width: 24px;
                height: 20px;
                margin-left: 15px;
                background: url('~@/assets/images/home/flow-arrow.png')
                    no-repeat;
                background-size: 100% 100%;
                content: ' ';
            }
            &:last-child::after {
                display: none;
            }
        }
        &-img {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 80px;
            height: 80px;
            margin: 0 auto;
            background: #fff;
            border: 1px solid $green;
            border-radius: 50%;
        }
        &-title {
            margin-top: 18px;
            font-size: 18px;
        }
        &-sub {
            margin-top: 7px;
            font-size: 14px;
            li {
                line-height: 21px;
            }
        }
    }
}
