@import '@/assets/styles/variable.scss';
@import '@/assets/styles/mixin.scss';

.home-schools {
    padding: 55px 0;
    text-align: center;
    background: $pageBackground;
    title {
        font-size: 24px;
        line-height: 45px;
        &.sub {
            font-size: 16px;
            line-height: 22px;
        }
    }
    .schools {
        &-container {
            margin-top: 40px;
            ul {
                display: flex;
                justify-content: center;
                &.opacity3 {
                    opacity: 0.8;
                }
                &.opacity4 {
                    opacity: 0.5;
                }
                &.opacity5 {
                    opacity: 0.2;
                }
            }
            li {
                margin: 10px;
                padding: 10px 22px;
                font-size: 16px;
                background: #fff;
                border: 1px solid #e5e5e5;
            }
        }
    }
}
