// 标准颜色
$green: #16a83f;
$pageBackground: #f5f5f5;

// 字体颜色

// 圆角大小
$radius8: 8px;
$radius4: 4px;

// 其他
$width: 1180px; //内容基本宽度
