@import '@/assets/styles/variable.scss';
.home-team {
    padding: 55px 0;
    background: #fff;
    .team {
        &-title {
            font-size: 32px;
            line-height: 45px;
            text-align: center;
            .sub {
                font-size: 16px;
                line-height: 22px;
            }
        }
        &-leader {
            img {
                width: 100%;
                margin-top: 20px;
            }
        }
        &-member img {
            width: 100%;
            margin-top: 20px;
        }
    }
}
.show-box {
    width: 1180px;
    margin: 0 auto;
}
