body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, Helvetica Neue, PingFang SC,
        Microsoft YaHei, Source Han Sans SC, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #f5f5f5;
}
#root {
    position: relative;
    min-height: 100%;
}
.menu {
    font-size: 15px;
    &-item {
        a {
            color: #222;
            &:active,
            &:hover {
                color: #16a83f;
                font-weight: bold;
            }
        }
    }
    .active {
        position: relative;
        color: #16a83f;
        font-weight: bold;
        &::after {
            position: absolute;
            top: 25px;
            left: 50%;
            display: block;
            width: 30px;
            height: 2px;
            background: #16a83f;
            transform: translateX(-50%);
            content: ' ';
        }
    }
}
.MathJax {
    outline: none;
}

@primary-color: #16A83F;@text-color: #222;@border-radius-base: 4px;