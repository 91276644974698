@import '@/assets/styles/variable.scss';
#footer {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
}
.app-footer {
    margin: 0 auto;
    background: #222;
    .title {
        font-size: 18px;
    }
    .footer {
        &-contents {
            display: flex;
            justify-content: space-between;
            width: $width;
            height: 245px;
            margin: 0 auto;
            padding-top: 40px;
            color: #fff;
            .logo img {
                width: 126px;
                height: 60px;
                margin-bottom: 18px;
            }
            .intro {
                color: #999;
                font-size: 12px;
                line-height: 18px;
            }
        }
        &-about {
            width: 280px;
        }
        &-contact {
            .title {
                margin-bottom: 20px;
                font-size: 16px;
            }
            .mobile {
                margin-top: 12px;
                color: #999;
                font-size: 14px;
                div {
                    display: inline;
                    margin-left: 2px;
                    color: #fff;
                }
            }
            .promotion {
                display: flex;
                justify-content: space-between;
                width: 264px;
                margin-top: 26px;
                &-item {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    color: #999;
                    font-size: 12px;
                    img {
                        width: 30px;
                        height: 30px;
                        margin-bottom: 12px;
                        filter: opacity(0.5);
                        &:hover {
                            filter: opacity(1);
                        }
                    }
                }
            }
        }
        &-apps {
            .title {
                font-size: 16px;
            }
            .apps-list {
                display: flex;
                justify-content: space-between;
                width: 250px;
                margin-top: 20px;
                &-item {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    color: #999;
                    font-size: 12px;
                    img {
                        width: 40px;
                        height: 40px;
                        margin-bottom: 12px;
                    }
                }
            }
            .more {
                margin-top: 15px;
                color: #666;
                font-size: 12px;
            }
        }
    }
    .copyright {
        height: 40px;
        color: #999;
        font-size: 12px;
        line-height: 40px;
        text-align: center;
        background: #111;
    }
    :global(.anticon) {
        margin-right: 8px;
    }
}
.promotion-content {
    display: flex;
    &-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-right: 20px;
        font-size: 12px;
        &:last-child {
            margin-right: 0;
        }
        img {
            width: 120px;
            height: 120px;
            margin-bottom: 6px;
        }
    }
}
:global(.footer-promotion-popver) {
    :global(.ant-popover-inner-content) {
        padding: 12px 12px 6px;
    }
}
