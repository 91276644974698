@import '@/assets/styles/variable.scss';

.header {
    background-color: #fff;

    .wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: $width;
        height: 68px;
        margin: 0 auto;
    }

    .menu {
        display: flex;

        li {
            margin-right: 40px;
            a::before {
                display: block;
                height: 0;
                overflow: hidden;
                font-weight: bold;
                visibility: hidden;
                content: attr(title);
            }
            a:hover {
                font-weight: normal;
            }
            a:focus {
                font-weight: bold;
            }
        }
    }

    .logo {
        display: flex;
        align-items: center;
        color: $green;

        img {
            width: 117.36px;
            height: 28.05px;
            margin-right: 16px;
        }

        &-text {
            font-size: 26px;
        }
    }
}

.navi-name {
    display: inline-block;
    text-align: right;
}

.login {
    margin-left: -20px;
    padding-left: 20px;
    color: #666;
    border-left: 1px solid #e5e5e5;
    cursor: pointer;

    :global(.anticon-user) {
        margin-right: 5px;
    }

    a {
        color: #666;
    }
    &:hover {
        * {
            color: #16a83f;
        }
    }
}

.personal-menu {
    width: 140px;
    padding: 10px 0;
    li {
        height: 40px;
        line-height: 40px;
    }

    a {
        padding: 0;
        text-align: center;

        &:hover {
            color: #16a83f;
        }
    }

    button {
        &:hover {
            color: #16a83f;
        }
    }
}

.child-menu {
    width: 140px;
    padding: 10px 0;
    text-align: center;
    li {
        height: 40px;
        line-height: 30px;
    }
    a:hover {
        color: #16a83f;
    }
}
.logout-button {
    &:hover {
        background: transparent;
    }
}
