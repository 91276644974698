@import '@/assets/styles/variable.scss';
@import '@/assets/styles/mixin.scss';

.homepage {
    width: 100%;
    max-width: 1920px;
    title {
        font-size: 24px;
        &.bg {
            width: 285px;
            height: 45px;
            padding-top: 15px;
            background: url('~@/assets/images/home/subtitle-bg.png');
            background-size: 100% 100%;
        }
    }
    :global(.ant-tabs-tab) {
        margin: 0;
        padding: 20px;
        font-size: 16px;
    }
    .home {
        &-banner {
            height: 460px;
            background: url('~@/assets/images/home/banner-bg.png');
            background-position: center;

            .banner {
                &-container {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    width: $width;
                    height: 100%;
                    margin: 0 auto;
                }

                &-video {
                    position: relative;
                    width: 580px;
                    height: 326px;
                    overflow: hidden;
                    background: #fff;
                    border-radius: $radius8;
                }
            }
            .video-bg {
                width: 100;
                height: 100%;
            }
            .play-btn {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                cursor: pointer;
                &:hover {
                    opacity: 0.8;
                }
            }
        }

        &-knowledge {
            padding-top: 55px;
            background: #fff;
            :global(.knowledge-tab) {
                margin-top: 15px;
            }
            :global(.knowledge-tab-icon) {
                width: 30px;
                height: 30px;
                margin-right: 10px;
            }
            .knowledge-title {
                text-align: center;
            }
            .knowledge-container {
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: $width;
                margin: 0 auto;
                padding: 24px 0 60px 0;

                .info {
                    width: 340px;
                    font-size: 18px;

                    &-content {
                        margin-top: 16px;
                        color: #666;
                        font-size: 14px;
                        line-height: 21px;
                    }

                    button {
                        margin-top: 16px;
                    }
                }

                .screenshot {
                    border-radius: $radius8;

                    img {
                        width: 830px;
                        height: 450px;
                    }
                }
            }
        }
        &-publish {
            padding: 55px 0 36px 0;
            .publish {
                &-title {
                    margin-bottom: 15px;
                    text-align: center;
                }
                &-img {
                    display: block;
                    width: 1180px;
                    height: 341px;
                    margin: 24px auto;
                }
            }
            :global(.publish-tab-icon) {
                width: 30px;
                height: 30px;
                margin-right: 10px;
            }
        }
        &-vip {
            max-width: 1920px;
            height: 196px;
            background: url('~@/assets/images/home/vip-bg.png') no-repeat;
            background-position: center;
            background-size: auto 196px;
        }
    }
}
